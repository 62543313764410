html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes fadeInOnAppear {
  0% { transform: translateY(50%); opacity: 0%;}
  100% { transform: translateY(0%); opacity: 100%;}
}

.App-header {
  background-color: #151515;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*MAIN WINDOW*/

.x {
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

/*NAV BAR BUTTONS*/


.tabs {
  display: flex;
  top: 0;
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  position: fixed;
  height: 10vh;
}

.homeButton {
  background-color: #151515;
  border: none;
  color: rgb(200, 200, 200);
  padding: 15px 32px;
  transition-duration: 0.4s;
  padding-left: 100px;
}

.homeButton:hover {
  color: white;
}

.aboutButton {
  background-color: #151515;
  border: none;
  color: rgb(200, 200, 200);
  padding: 15px 32px;
  transition-duration: 0.4s;  
}

.aboutButton:hover {
  color: white;
}

.projectsButton {
  background-color: #151515;
  border: none;
  color: rgb(200, 200, 200);
  padding: 15px 32px;
  transition-duration: 0.4s;
}

.projectsButton:hover {
  color: white;
}

.awardsButton {
  background-color: #151515;
  border: none;
  color: rgb(200, 200, 200);
  padding: 15px 32px;
  transition-duration: 0.4s;
}

.awardsButton:hover {
  color: white;
}

/*HOME*/

.Home {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.titleLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;
  padding-right: 100px;
  animation: 1s ease-out 0s 1 fadeInOnAppear;
}

.tl1 {
  font-size: 300%;
  font-weight: bold;
}

.tl2 {
  font-size: 500%;
  font-weight: bolder;
}

.tl3 {
  font-family: monospace;
}

.Socials {
  flex-direction: row;
  align-items: flex-start;
}

/*ABOUT*/

.About {
  display: flex;
  min-height: 100%;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  padding-left: 2%;
  padding-right: 10%;
}

.AboutTitle {
  width: 100%;
}

/*PROJECTS*/

.Projects {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  min-height: 100vh;
}

/*AWARDS*/

.Awards {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  height: 100vh;
}
